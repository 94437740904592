import { auth, session, setupAuthFromExternal } from '$stores/auth';
import { get } from 'svelte/store';
import type { PageLoad } from './$types';
import { browser } from '$app/environment';
import { redirect } from '@sveltejs/kit';

export const load = (async ({ parent }) => {
    const { master } = await parent();
    const { transformUrl } = master;

    await setupAuthFromExternal({ ignoreRedirect: true });
    // 빌드시 index 페이지가 생겨야 해서 브라우저 체크 필수
    if (browser && !get(session)) {
        throw redirect(302, transformUrl(`/sign-in?returnUrl=${encodeURIComponent('/')}`));
    }

    return {
        showGNB: false,
        showPageHeader: false,
        auth: get(auth),
        session: get(session),
    };
}) satisfies PageLoad;
