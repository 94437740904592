<script lang="ts">
    import { dev } from '$app/environment';
    import { HeadTitle } from '$lib/service';
    import { OverlayScrollbars } from 'overlayscrollbars';
    import { onMount } from 'svelte';
    import NavigationMenu from '../NavigationMenu.svelte';
    import type { PageData } from './$types';
    import Launcher from './Launcher.svelte';
    let contentsRef;

    export let data: PageData;
    onMount(() => {
        OverlayScrollbars(contentsRef, {
            scrollbars: {
                autoHide: 'scroll',
            },
        });
    });
</script>

<HeadTitle title={data.title} />

<div class="grid h-auto min-h-full {dev ? 'grid-cols-[auto_1fr]' : ''} ">
    <!-- 임시 LNB 삭제예정 -->
    {#if data.session && dev}
        <NavigationMenu />
    {/if}
    <div bind:this={contentsRef} class="h-full min-w-[50rem] flex-1">
        {#if data.session}
            <Launcher />
        {/if}
    </div>
</div>
